.react-date-picker__calendar abbr {
    text-decoration: none;
    font-size: 10px;
}

.react-calendar__tile.react-calendar__month-view__days__day abbr {
    font-size: 12px;
}

.react-date-picker__calendar > .react-calendar {
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(144, 177, 225, 0.38);
}

.react-calendar__navigation__label__labelText {
    text-transform: capitalize;
    font-size: 13px;
}

.react-calendar__tile.react-calendar__year-view__months__month {
    text-transform: capitalize;
}
.react-date-picker__clear-button.react-date-picker__button {
    display: none;
}

.react-date-picker {
    width: 100%;
}

.react-date-picker__wrapper {
    display: block;
    width: 100%;
    padding: 0 0.85rem;
    border: 1px solid var(--color-input-border-default) !important;
    border-radius: 0.25rem;
    transition: all 200ms ease-out;
    -webkit-transition: all 200ms ease-out;
    font-size: var(--input-size);
    min-height: 37.2px;
}

.filled-date-picker-container .react-date-picker__wrapper {
    background-color: white;
}

.react-date-picker--disabled .react-date-picker__wrapper {
    background: rgba(215, 215, 215, 0.5);
    cursor: not-allowed;
}

.filled-date-picker-container.flex {
    margin-bottom: 0;
}

.filled-date-picker-container.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filled-date-picker-container.flex > div {
    flex: 1;
    margin-left: 2rem;
}

.filled .react-date-picker__wrapper {
    background-color: white;
}

.filled.flex {
    margin-bottom: 0;
}

.filled.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filled.flex > div {
    flex: 1;
    margin-left: 2rem;
}
